<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="30"
    viewBox="0 0 32 30"
  >
    <defs>
      <path
        id="v982a"
        d="M1359.06 370.36l-7.78-8.02 3.9-4 3.88-4zm-27.47.66l7.98-8.2h10.87l7.98 8.2zm-.65-16.69l3.89 4 3.89 4.01-7.78 8.02zm3.75-3.19v5.7l-3.47-3.57zm.93-4.24h18.76v10.9l-3.94 4.05h-10.88l-3.93-4.05zm9.29-1.89l1.54.92h-3.28zm10.4 6.13l3.47 2.13-3.47 3.57zm4.64 1.89s0-.05 0 0c0-.05-.04-.05-.04-.1s-.05-.05-.05-.1c0-.04-.05-.04-.1-.04l-.04-.05-4.4-2.75v-3.58c0-.29-.2-.48-.48-.48h-6.42l-3.23-1.88h-.05c-.05 0-.05 0-.1-.05h-.19c-.04 0-.04 0-.08.05h-.05l-3.56 1.88h-6c-.29 0-.47.2-.47.48V350l-4.46 2.75-.04.05c-.05 0-.05.04-.1.04 0 0-.04.05-.04.1 0 0 0 .05-.05.05v18.54c0 .05 0 .14.05.19.04.1.14.2.23.24.05.05.14.05.19.05h29.06c.05 0 .14 0 .19-.05.1-.05.19-.14.23-.24.05-.05.05-.14.05-.2v-18.39c0-.04 0-.04-.05-.1z"
      />
      <path
        id="v982b"
        d="M1339.85 352.2h10.32c.28 0 .46-.19.46-.48s-.18-.48-.46-.48h-10.32c-.28 0-.46.2-.46.48 0 .3.18.49.46.49z"
      />
      <path
        id="v982c"
        d="M1350.17 355.88c.28 0 .46-.19.46-.47 0-.3-.18-.48-.46-.48h-10.32c-.28 0-.46.19-.46.48 0 .28.18.47.46.47z"
      />
    </defs>
    <g>
      <g transform="translate(-1329 -343)">
        <g>
          <use fill="#363636" xlink:href="#v982a" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".5"
            xlink:href="#v982a"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#v982b" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".5"
            xlink:href="#v982b"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#v982c" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".5"
            xlink:href="#v982c"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheMsgEnvelopeOpenedIcon',
};
</script>
